/**
*  make change in default material-ui theme
* @look_at https://www.gatsbyjs.com/plugins/gatsby-theme-material-ui/
**/

import React from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

import { responsiveFontSizes } from '@material-ui/core/styles';

// import material-ui components and contexts
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// jss to create rtl plugin for material-ui
import { create } from 'jss';
import rtl from 'jss-rtl'

// create plugin
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

/**
** @add_custom_configs
** make change in gatsby-theme-material-ui theme
**/
export default function TopLayout({ children, theme }) {


  /** 
  * custotheme shadows
  * @look_at https://github.com/mui-org/material-ui/issues/8289
  **/
  theme.shadows[1] = '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)';


  theme = responsiveFontSizes(theme);

  return (
    <StylesProvider jss={jss}>
      <ThemeTopLayout theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeTopLayout>
    </StylesProvider>
  );
}
