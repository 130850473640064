/**
*  make change in default material-ui theme
* @look_at https://www.gatsbyjs.com/plugins/gatsby-theme-material-ui/
**/
import { createMuiTheme } from "@material-ui/core";
import { red } from '@material-ui/core/colors';



// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
        'Tajawal',
        'sans-serif'
      ].join(','),
  },
  palette: {
    primary: {
      main: '#201F1',
    },
    secondary: {
      main: '#F55739',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: 'rgba(251, 251, 251, 0.5)',
    },
  },
  direction: "ltr",
});

export default theme;
